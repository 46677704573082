
//import BBPropertyPanel from './bb/BBPropertyPanel'
import BBPropertyPanel from './bb/BBPropertyPanelDescriptor'
import BBCheckboxTree from './bb/BBCheckboxTreeDescriptor'

import Table from './TableDescriptor'
import Table2 from './Table2'
import TableCells from './TableCells'
import Tree from './TreeDescriptor'
import TreeD3 from './TreeD3Descriptor';


import SelectBox from './SelectBox'
import Slider from './Slider'
import SearchAutocomplete from './SearchAutocomplete'
import Tab from './Tab'
import SplitLayout from './SplitLayout'
import SplitLayoutTest from './SplitLayoutTestDescriptor'
import Yasgui from './Yasgui'
import BBMapExperimental from './bb/BBMap'
import BBMap from './bb/BBMapExperimental'
import BBMultiMap from './bb/BBMultiMap'

import BBTreeTable from './bb/BBTreeTable'
import BBYasgui from './bb/BBYasgui'
import BBYasguiLatest from './bb/BBYasguiLatest'
import BBYasr from './bb/BBYasr'
import BBYasguiNotebook from './bb/BBYasguiNotebook'
import BBNotebook from './bb/BBNotebook'
import BBKGApiVizWidget from './bb/BBKGApiVizWidget'
import BBVoyager from './bb/BBVoyager'
import BBAccordion from './bb/BBAccordionDescriptor'
import BBPicture from './bb/BBPicture'
import BBHTML from './bb/BBHTML'
import BBDForm from './bb/editor/BBDFormDescriptor'
//import BBForm from './bb/editor/BBForm';
//import BBGrid from './bb/editor/BBGrid';
import BBSideMenu from './bb/BBSideMenuDescriptor'
import BBEChart from './bb/BBEChartDescriptor'
import BBBoorkern from './bb/BBBoorkern'
import StartRuleButton from './bb/rules/StartRuleButtonDescriptor'

import BBVis from './bb/BBVis'
import BBLinkButtons from './bb/BBLinkButtons'
import BBIFCViewer from './bb/BBIFCViewer'
import BBStructuredText from './bb/BBStructuredTextDescriptor'

//import BBLayout from './bb/BBLayout';
import BBKVKProductTemplate from './bb/kvk/BBKVKProductTemplateDescriptor'
import BBKVKFlexibleTextWidget from './bb/kvk/BBKVKFlexibleTextWidget'
import BBBackMenu from './bb/BBBackMenu'
import BBSimplePushButton from './bb/BBSimplePushButtonDescriptor'
import BBVenn from './bb/BBVenn'
import BBForm from './bb/BBForm'
import BBUpload from './bb/BBUploadDescriptor'
import BBNOML from './bb/BBNOML'
import ButtonPopup from './bb/rotterdam/ButtonPopupDescriptor'
import BBRDFViewer from './bb/BBRDFViewer'
import BBCesium from './bb/BBCesium'
//import BBRDFViewerOnline from './bb/BBRDFViewerOnline'
//import BBMultiSelect from './bb/BBMultiSelect'
import BBSVG from './bb/BBSVG'
//import BBThreeJS from './bb/BBThreeJS'
import BBDWegselectorDescriptor from './bb/venr/BBWegselectorDescriptor';
import BBParameterLinkEmptyWidget from './bb/BBParameterLinkEmptyWidget'
import { SequencePaths } from './SequencePaths'
import BBIFCJSViewer from './bb/BBIFCJSViewerDescriptor'
import BBThreeJSViewer from './bb/ifc/BBThreeJSViewerDescriptor'
import BBSparnatural from './bb/BBSparnaturalDescriptor'
//import LDWizardDescriptor from './bb/ldwizard/LDWizardDescriptor'
import NebulaDescriptor from './bb/deck/NebulaDescriptor'
import { textInputWidgetDescription } from './TextInput'
import { dynamicFilterWidgetDescription } from "./DynamicFilter"
import { excelWidgetDescription } from './ExcelWidgetDescriptor'
import { toggleWidgetDescription } from './ToggleWidget'
// import { editableTableDescription } from './EditableTable/editableTableDescription'
import { jobStatusWidgetDescription } from './jobStatusWidgetDescription'

import BBLibremapDescriptor from './bb/BBLibremapDescriptor'
import BBMermaidDescriptor from './bb/BBMermaidDescriptor'
// basic
import ListDescriptor from './ListDescriptor'
import RadioBoxDescriptor from './RadioBox'
import { TablePlusPlusDescription } from './TablePlusPlus/tablePlusPlusWidgetDescription'
// import Search from './Search';
import IFCJSViewerV3Descriptor from './ifcjs/IFCJSViewerV3Descriptor'
import BBIfcViewerNextGen from "./bb/BBIfcViewerNextGen"
import BBTableDescriptor from './bb/BBTableDescriptor'
import BBSVGEditorDescriptor from "./bb/BBSVGEditorDescriptor"

let widgetDefinitions = [
  //layout

  BBSideMenu.getDescription(),
  SplitLayout.getDescription(),
  SplitLayoutTest.getDescription(),
  Tab.getDescription(),
  BBAccordion.getDescription(),
//basic


  BBEChart.getDescription(),
  BBVis.getDescription(),
  
  ListDescriptor.getDescription(),

  Slider.getDescription(),
  SelectBox.getDescription(),
  //BBMultiSelect.getDescription(),
  RadioBoxDescriptor.getDescription(),
  SearchAutocomplete.getDescription(),
  Tree.getDescription(),
  SequencePaths.getDescription(),
  BBCheckboxTree.getDescription(),
  
  //BBPropertyPanel.getDescription(),
  BBSVGEditorDescriptor.getDescription(),
  BBPropertyPanel.getDescription(),
  TreeD3.getDescription(),
  Table.getDescription(),
  Table2.getDescription(),
  TableCells.getDescription(),
  textInputWidgetDescription,
  {
    name: 'Yasgui',
    component: Yasgui,
    label: 'Yasgui widget',
  },
  //editableTableDescription,
  TablePlusPlusDescription,
  BBTableDescriptor.getDescription(),
  BBTreeTable.getDescription(),
  BBPicture.getDescription(),
  BBHTML.getDescription(),
  BBBackMenu.getDescription(),
  BBSimplePushButton.getDescription(),
  dynamicFilterWidgetDescription,
  // 2d



  
 
  BBMap.getDescription(),
  BBMapExperimental.getDescription(),
  BBLibremapDescriptor.getDescription(),
  BBMultiMap.getDescription(),

  //3d
  BBCesium.getDescription(),
  BBIFCViewer.getDescription(),
  BBIFCJSViewer.getDescription(),
  BBThreeJSViewer.getDescription(),


  //edit
  BBYasgui.getDescription(),
  BBYasguiLatest.getDescription(),
  {
    name: 'BBYasguiNotebook',
    component: BBYasguiNotebook,
    label: 'BB YasGUINotebook',
    variables: [],
  },
  {
    name: 'BBNotebook',
    component: BBNotebook,
    label: 'BB Notebook',
    variables: [],
  },
  BBYasr.getDescription(),
  {
    name: 'BBKGApiVizWidget',
    component: BBKGApiVizWidget,
    label: 'BBKGApiVizWidget',
    variables: [
      {
        name: 'query',
        type: 'yasgui',
        label: 'just to start. ',
      },
    ],
  },
  BBDForm.getDescription(),



  // graphics
  BBNOML.getDescription(),
  BBVoyager.getDescription(),
  BBMermaidDescriptor.getDescription(),
  BBBoorkern.getDescription(),
  
//utils

 

//other
  BBKVKProductTemplate.getDescription(),
  BBKVKFlexibleTextWidget.getDescription(),
  BBLinkButtons.getDescription(),
  


  BBStructuredText.getDescription(),
  BBVenn.getDescription(),
  BBSVG.getDescription(),
  BBForm.getDescription(),
  BBUpload.getDescription(),
  ButtonPopup.getDescription(),
  BBRDFViewer.getDescription(),
  //BBRDFViewerOnline.getDescription(),
//  BBThreeJS.getDescription(),
 //LDWizardDescriptor.getDescription(),
  BBDWegselectorDescriptor.getDescription(),
  BBParameterLinkEmptyWidget.getDescription(),

  StartRuleButton.getDescription(),
  NebulaDescriptor.getDescription(),
  BBSparnatural.getDescription(),
  
  excelWidgetDescription,
  toggleWidgetDescription,
  jobStatusWidgetDescription,
  IFCJSViewerV3Descriptor.getDescription(),
  BBIfcViewerNextGen.getDescription()
 
]

export default widgetDefinitions
