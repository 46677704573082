import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import styles from './Viewer.module.css'
import { Button, Input, Label } from 'reactstrap'
import { FormGroup } from './FormGroup'
import Dialog from './Dialog'
import axios from 'axios'
import { ConfigurationTopBar } from './ConfigurationTopBar'
import Header from './Header'
import Footer from './Footer'
import Notification from './Notification'
import Area from './Area'
import Spinner from './Spinner'
import defaultConfig from './defaultConfig.json'
import { Helmet } from 'react-helmet'
import { Dialog as InfoDialog, MenuItem, Select } from '@mui/material'
import { inflateLayout, LAYOUT_NAME } from '../helpers/layout'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AutoHyperlink } from './AutoHyperlink'
import { FETCH_VIEWER_SUCCESS, SET_LAYOUT_NAME } from '../redux/project'
import { fixupLegacyRepoURL, getCgiUrl } from "../helpers/url"
import { getAndSetDefaultParameterValues } from './ViewerContextUtils'
import { AppVariables } from './AppVariables'
import { FontListButton } from "./FontList"
import { SparqlTriggerExecutor } from './SparqlTriggerExecutor'
import { JsonEditor, JsonTextEditor } from "./JsonEditor"
import { format as formatDate } from "date-fns"
import "typeface-exo"
import "@fontsource-variable/exo-2"
import { ConfirmPopup } from 'primereact/confirmpopup'
import { GlobalToast } from "./GlobalToast"

export class Viewer extends Component {

  /**
   * @private
   * @type {Viewer | null}
   */
  static _instance = null

  state = {
    popupInfoVisible: false,
    dialogVisible: false,
    notification: {},
    loading: true,
    showJSONEditor: false,
    config: null,
  }

  static getInstance() {
    return Viewer._instance
  }

  onJsonEditClick = () => {
    this.setState({ showJSONEditor: true })
  }

  getUrl(data) {
    //console.log("get URL ",data);
    try {
      let url = '/api/sparql/' + data.repoURL
      if (data.repoURL.startsWith('http')) {
        // request sparql endpoint directly
        url = data.repoURL
      }
      var modus = false
      if (window.location.host === 'localhost:3000') {
        modus = true
      }
      //  var modus=false; //debug

      if (data.localRepoURL != null && data.localRepoURL !== '' && modus) {
        url = data.localRepoURL
      }
     
      return url
    } catch (e) {
      console.log('error creating url ', e)
    }
    return null
  }

  /**
   * @param bbsparqlendpoint {string}
   * @param repoUrl {string}
   * @return {string}
   */
  constructRepoLoginPath(bbsparqlendpoint, repoUrl) {
    const { viewerPath } = getViewProps(this.props)
    const repoLoginParams = new URLSearchParams(bbsparqlendpoint)
    repoLoginParams.set('repoUrl', repoUrl)
    repoLoginParams.set('viewerPath', viewerPath)

    return '/loginbb?id=' + encodeURIComponent(btoa('?' + repoLoginParams.toString()))
  }

  async componentDidMount() {
    const { projectPath, viewerPath } = getViewProps(this.props)

    Viewer._instance = this

    let viewer = this.props.project.viewers.find((v) => v.path === viewerPath)
    if (viewer && viewer.loaded) {
        console.log("component did mount load into state");
      return this.loadViewerIntoState(viewer)
    }
    // no idea where CONFIG_URL comes from....
      if ((window.CONNECTED_APPS_STATIC) && (window.CONFIG_URL==null)) 
      {
        window.CONFIG_URL="./config.json";
      }
      
    const request = window.CONNECTED_APPS_STATIC
      ? {
          method: 'get',
          url: window.CONFIG_URL,
          headers: { 'Content-Type': 'application/json' },
        }
      : {
          method: 'get',
          url: `/api/projects/${projectPath}/viewers/${viewerPath}`,
          headers: { Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}` },
        }

    let config
    try {
     // console.log(request);

      const configResponse = await axios(request)
      config = configResponse.data
    } catch(error) {
      if (error.response?.status === 401) return this.props.history.push('/login', `projects/${projectPath}/${viewerPath}`)
      if (error.response?.status === 404) return this.loadNewViewerConfig(viewerPath)
      throw error
    }
    if (!config) throw new Error('somehow no config was fetched')

    if (window.CONNECTED_APPS_STATIC == null && config.repoURL.startsWith('http'))
    {
      if (config.repoURL.includes("BloodBee"))
      {
            // (Hans: so annoying so skipping this when it contains BloodBee
      }
      else
      {
        alert(`Attention: This app interacts with external triplestore-endpoint "${config.repoURL}". Ignore this message if this is on purpose. This message will not show up when this app is locked-and-published.`)
      }
      
    }


    if (config.prejs != null) {
      try {
        eval(config.prejs) // eslint-disable-line no-eval
      } catch (error2) {
        console.log(error2, config.prejs)
      }
    }

    const loadConfig = async () => {
      console.log("load config load into state");
      await this.loadViewerIntoState(config)
      this.props.dispatch({ type: FETCH_VIEWER_SUCCESS, data: config })
      this.keepAlive('init')
    }

    if (this.shouldConnectToWistorBackend(config)) {
      const auth = await this.reauth(config)
      if (auth.isAutoLogin && !auth.success) return auth.goToLoginPage()

      const isAuthorised = await this.isAuthorised(config)
      const user = isAuthorised.user
      if (true) // to retreive incorrect configs. previously I could use ?debug=skipLogin to skip to login procedure resulting in starting up the client without sparql endpoint access. This allows an editor to set the correct parameters
        {
        if (!user) 
          {
           
            if (window.location.search.toLowerCase().includes("debug=skiplogin")) {
              console.log("debug skiplogin");
              loadConfig();return;
            }
            throw new Error('somehow no user data was fetched')
          }
        if (!isAuthorised.success) return isAuthorised.goToLoginPage()


        this.publish('user.fullAccess', Boolean(user.fullAccess))
      
        if (user.parameters!=null) document.userParameters=user.parameters;
        if (document.userParameters==null) document.userParameters={};
        document.userParameters.userUri=user.userUri;
        document.userParameters.userName=user.userName;
      }
    }

    loadConfig()
  }

  /** @param {'init' | 'destroy'} mode */
  keepAlive = (mode) => {
    if (mode === 'init') {
      if (!this.keepAliveInterval) setInterval(() => this.isAuthorised(), 5*60*1000)
      return
    }

    if (mode === 'destroy') {
      if (!this.keepAliveInterval) return
      clearInterval(this.keepAliveInterval)
      return this.keepAliveInterval = null
    }


    throw new Error(`unknown mode: ${JSON.stringify(mode)}`)
  }

  /**
   * @param {Record<string, unknown>} config
   * @param {Object} options
   * @param {boolean} [options.silent=false] - If true, suppresses logging output and alerts. Unexpected errors are still logged.
   * @returns {Promise<{ user: any, success: boolean, goToLoginPage: () => any }>}
   */
  async isAuthorised(config, options = { silent: false }) {
    const {isLoggedInUrl, viewerPath, goToLoginPage, repo, repo2} = this.viewerParams(config)
    let user
    try {
      const userResponse = await axios({
        method: 'get',
        url: isLoggedInUrl,
        withCredentials: true,
        params: { viewerPath }
      })
      user = userResponse.data
    } catch(error) {
      console.error(error)
    }

    const success = (() => {
      if (!user) {
        if (!options?.silent) {
          console.error('no user data was fetched')
        }
        return false
      }
     
        if (user.loggedIn==null) {
          console.error('no user data was fetched (22)')
          return false
        }
       
     


      if (user.loggedIn.toLowerCase() !== 'true') return false

      if (user.oidcLoginStatus === 'USER_NOT_PERMITTED' || user.oidcLoginStatus === 'REPO_NOT_PERMITTED') {
        if (!options?.silent) {
          alert('Your account does not have sufficient permissions for this application.')
        }
        return false
      }

      if (repo == null || user.repo == null) return false

      if (repo.toLowerCase() !== user.repo.toLowerCase() && repo2.toLowerCase() !== user.repo.toLowerCase()) {
        if (!options?.silent) {
          alert('wrong repo. This account is not valid for this application.')
        }
        return false
      }

      return true
    })()

    return {user, goToLoginPage, success}
  }

  /**
   * @param {any} config
   * @returns {Promise<{ isAutoLogin: boolean, success: boolean, goToLoginPage: () => any }>}
   */
  async reauth (config = this.state.viewer) {
    if (config == null) throw new Error('Reauth requested without any config loaded')

    const { isAutoLogin, psw, login, autoLoginUrl, goToLoginPage } = this.viewerParams(config)
    if (!isAutoLogin) return { isAutoLogin, goToLoginPage, success: false }

    let success
    try {
      this.autoLogin = this.autoLogin ?? axios({
        method: 'post',
        url: autoLoginUrl,
        withCredentials: true,
        data: { login, psw },
      })
      const response = await this.autoLogin
      success = response.data?.loggedIn?.toLowerCase?.() === 'true'

    } catch(error) {
      success = !(error?.response?.status === 401 || error?.response?.status === 403)
      if (!success) alert('Default login credentials are incorrect. Login with valid credentials and change it to fix this.')
    }

    this.autoLogin = null
    return { isAutoLogin, goToLoginPage, success }
  }

  viewerParams(config = this.state.viewer) {
    const { projectPath, viewerPath } = getViewProps(this.props)

    const params = new URLSearchParams(config.bbsparqlendpoint)
    const repoName = params.get('repo')
    const repo2 = params.get('repo2') ?? ''
    const psw = params.get('psw')
    const login = params.get('login')
    const isAutoLogin = psw != null && login != null

    const repoURL = fixupLegacyRepoURL(config.repoURL, repoName)
    const localRepoURL = fixupLegacyRepoURL(config.localRepoURL, repoName)

    const sparqlUrl = getCgiUrl('sparql', repoName, repoURL, localRepoURL)
    const autoLoginUrl = getCgiUrl('login', repoName, repoURL, localRepoURL)
    const isLoggedInUrl = getCgiUrl('isloggedin', repoName, repoURL, localRepoURL)
    const repoLoginUrl = this.constructRepoLoginPath(config.bbsparqlendpoint, sparqlUrl)

    const goToLoginPage = () => this.props.history.replace(repoLoginUrl, this.props.location.pathname)

    return {projectPath, viewerPath, repo: repoName, repo2, isAutoLogin, psw, login, autoLoginUrl, sparqlUrl, isLoggedInUrl,
      repoURL, localRepoURL, repoLoginUrl, goToLoginPage}
  }

  shouldConnectToWistorBackend(config) {
    const isConnectedToLocalRepo = config.localRepoURL?.includes(':7200') && window.location.host === 'localhost:3000'
    if (isConnectedToLocalRepo) return false
    if (config.deleted) return false
    if (window.location.search.includes("debug=skiplogin")) return false
    return Boolean(config.bbsparqlendpoint?.includes?.('repo='))
  }

  loadNewViewerConfig(viewerPath) {
    this.setState({
      viewer: inflateLayout({ ...defaultConfig, path: viewerPath, new: true }),
      loading: false,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const viewer = this?.state?.viewer
    if (viewer == null) return

    if (viewer !== prevState.viewer) {
      this.props.dispatch({
        type: 'SET_VIEWER_CONFIG',
        data: viewer
      })
    }

    if (this.state.viewer.update60Enabled) {
      // console.log("we shoud update 60 ",this.state);
      if (this.props.update60Running) {
        // console.log("but is already running");
      } else {
        if (this.update60) return;
        this.update60=true;

       //  console.log("starting update 60 ");
         
      var me =this;
         setTimeout(function () {
         
          me.changingProps()},500 );  // why is this suddenly a problem?
       // ()
      }
    } else {
     //  console.log("update 60 should not be running")
      if ( (true) && (this.state.update60Running) ) {
        console.log("disabling update 60")
        this.props.dispatch({
          type: 'PUBLISH',
          data: {
            update60Running: false,
          },
        })
        //  console.log("disabling update 60")
      } else {
        // console.log("update60 is not running");
      }
    }

    const isSaved = this.state.viewer.id != null
    if (!isSaved) {
      this.save({ showNotification: false }) // Do not show 'saved' notification on creation of a new app
    }

  }

   publish = (topic, value) => {
    this.props.dispatch({ type: 'PUBLISH', data: { [topic]: value } })
  }

  loadViewerIntoState(viewer) {
    if (this.loadViewerIntoStateOnlyOnce!=null) return;
    this.loadViewerIntoStateOnlyOnce=true;
   // console.log("Load Viewer Into State ",viewer);
    return new Promise((resolve, reject) => {
      try {
        this.setState({
          viewer: inflateLayout(viewer),
          loading: false,
          popupInfoVisible: viewer.popupInfoAtStart,
        }, resolve)
        this.props.dispatch({ type: SET_LAYOUT_NAME, data: viewer.layoutName })
        this.registerAppCss(viewer.css)

        var r = getAndSetDefaultParameterValues(viewer);
        for (var key in r) {
        //  console.log("setting default parameters ",key,r[key])
          this.publish(key,r[key]);
        }
      } catch(e) {
        reject(e)
      }
    })
  }

  saveTriggers = async (triggers) => {
    await this.updateViewer({triggers})
    await this.save()
  }

  async updateViewer(partialViewer) {
    return new Promise(resolve => {
      this.setState({ viewer: { ...this.state.viewer, ...partialViewer } }, () => resolve())
    })
  }

  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    )
  }
  changingProps() {
    if (this.state.update60Running) return
    if (this.props.update60Running) return
    // console.log("setting first update60 value");

    this.props.dispatch({
      type: 'PUBLISH',
      data: {
        update60: 'start',
        update60Running: true,
      },
    })

    this.changingProps60(true)
  }

  changingProps60(b) {
    if (b || this.state.viewer.update60Enabled) {
      var me = this
      if (me.loop60) return
      me.loop60 = true
      setTimeout(function () {
         console.log("changing update60 value ");
        let id = me.uuidv4()
        me.props.dispatch({
          type: 'PUBLISH',
          data: {
            update60: id,
          },
        })
       // console.log('changing 60')
        me.loop60 = false
        me.changingProps60(false)
      }, 60000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this.keepAlive('destroy')
    Viewer._instance = null
  }

  onChange = (config) => {
    let viewer = { ...this.state.viewer, mainArea: config }
    this.setState({ viewer })
  }

  selectLayout = (layoutName) => {
    let viewer = inflateLayout({ ...this.state.viewer, layoutName })
    this.setState({ viewer })
    this.props.dispatch({ type: SET_LAYOUT_NAME, data: layoutName })
  }

  /** @type {Promise<void> | null} */
  savingPromise = null
  save = async (opts) => {
    if (this.savingPromise) {
      try {
        await this.savingPromise
      } catch (e) {
        // ignore
      }
    }

    this.registerAppCss(this.state?.viewer?.css)
    this.savingPromise = this.saveImpl(opts)
    return this.savingPromise
  }

  saveImpl = async ({showNotification = true} = {}) => {
    let viewer = this.state.viewer
    delete viewer.project

    const { projectPath, viewerPath } = getViewProps(this.props)

    try {
      const response = await axios({
        method: viewer.new ? 'post' : 'put',
        url: `/api/projects/${projectPath}/viewers` + (viewer.new ? '' : `/${viewerPath}`),
        headers: { Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}` },
        data: viewer,
      })
      const { data } = response

      if (showNotification) {
        if (this.timer) clearTimeout(this.timer)
        this.setState({ notification: { text: 'saved', type: 'success' } })
        this.timer = setTimeout(() => this.setState({ notification: {} }), 3000)
      }

      this.setState({ viewer: inflateLayout({ ...data, new: false }) })
      const {project, ...strippedData} = data
      this.props.dispatch({ type: 'SAVE_VIEWER_SUCCESS', data: strippedData })

    } catch(error) {

      if (error.response.status === 401) {
        this.props.history.push('/login', `projects/${projectPath}/${viewerPath}`)
      } else {
        this.setState({
          notification: {
            text: 'not saved: ' + error.response.data,
            type: 'failure',
          },
        })
        this.timer = setTimeout(() => this.setState({ notification: {} }), 3000)
      }

    }
  }

  /**
   * @param {string} [appCssJson] appCss JSON-string of something that should be Record<string, string | number>
   */
  registerAppCss (appCssJson) {
    if (!appCssJson) return this.props.dispatch({type: 'SET_APP_CSS'})
    try {
      const appCss = JSON.parse(appCssJson)
      this.props.dispatch({type: 'SET_APP_CSS', data: appCss})
    } catch(e) {
      console.error(e)
    }
  }

  onDialogOkClick = () => {
    this.setState({ dialogVisible: false })
    this.save()
  }

  onHeaderClick = (e) => {
    if (getViewProps(this.props).mode === 'edit') {
      this.setState({ dialogVisible: true })
    }
  }

  jsonOKClick = () => {
    this.setState({ showJSONEditor: false })
  }

  /**
   * @param voterId {string}
   * @param userActionsDisabled {boolean}
   */
  voteToDisableUserActions = (voterId, userActionsDisabled) => {
    this.props.dispatch({
      type: "VOTE_USER_ACTIONS_DISABLED",
      data: { voterId, value: userActionsDisabled }
    })
  }

  render() {
    // console.log("viewer render function",this);
    if (this == null) return <Spinner />
    //  if (true){ var id="halalo"; this.props.history.push('/loginbb?id='+id, this.props.location.pathname); return null;}
    if (this.state == null) return <Spinner />
    if (this.state.loading) return <Spinner />
    /** @type {LoginState} */
    const loginState = this.props.loginState

    let viewer = this.state.viewer
    if (!viewer || viewer.deleted) return (
      <p>This viewer does not exist or has been deleted. Please contact an admin if this is a problem.</p>
    )

    var me = this
    document.st = function () {
      viewer.notShowTop = false
      me.setState({ notShowTop: false })
    }
    document.preview = function () {
      getViewProps(this.props).mode = 'preview'
      me.setState({ mode: 'preview' })
    }
    document.edit = function () {
      getViewProps(this.props).mode = 'edit'
      me.setState({ mode: 'edit' })
    }

    let notification = this.state.notification
    const { mode, projectPath, viewerPath } = getViewProps(this.props)

    let showBottom = viewer.notShowBottom
    if (showBottom == null) {
      showBottom = true
    } else {
      showBottom = !showBottom
    }

    let showTop = viewer.notShowTop
    if (showTop == null) {
      showTop = true
    } else {
      showTop = !showTop
    }

    let css = viewer.css
    if (css) {
      try {
        css = JSON.parse(css)
      } catch (error) {
        css = {}
      }
    } else {
      css = {}
    }

    const { repo: repoName } = this.viewerParams(viewer)

    return (
      <div className={styles.wrapper} style={css}>
        {viewer.triggers?.map(trigger => (
          <SparqlTriggerExecutor
            signalIsFetching={(isFetching) => {
              if (trigger.voteForUserActionsDisabledWhenLoading)
                this.voteToDisableUserActions(trigger.label, isFetching)
            }}
            key={trigger.label}
            templateId={trigger.label}
            endpoint={getCgiUrl('sparql', repoName, viewer.repoURL, viewer.localRepoURL)}
            sparqlTemplate={trigger.sparqlQuery}
            pubVars={this.props.pubsub}
            publish={this.publish}
            dispatch={this.props.dispatch}
          />))}
        <div className={styles.mainContainer}>

          <Helmet>
            <title>{viewer.title}</title>
            {viewer.project && (
              <link
                rel="icon"
                type="image/png"
                href={`${window.CONNECTED_APPS_STATIC ? '.' : ''}/favicon-${viewer.project.path}.png`}
                sizes="16x16"
              />
            )}
          </Helmet>
          <Notification messageType={notification.type} text={notification.text} />

          {!showTop && loginState.isLoggedIn && (
            <>
              {(new URLSearchParams(window.location.search)).get('hover') === 'app-variables' && (
                <AppVariables />
              )}
            </>
          )}

          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {!window.CONNECTED_APPS_STATIC && (
              <ConfigurationTopBar
                loginState={loginState}
                openAppSettings={() => {this.setState({ dialogVisible: true}) }}
                mode={mode}
                projectPath={projectPath}
                viewerPath={viewerPath}
                repoURL={viewer.repoURL}
                saveTriggers={this.saveTriggers}
                triggers={viewer.triggers ?? []}
              />
            )}
            <div style={{ position: 'relative', flexGrow: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
              {showTop && (
                <Fragment>
                  <Header
                    title={viewer.title}
                    subtitle={viewer.subtitle}
                    repoURL={viewer.repoURL}
                    localRepoURL={viewer.localRepoURL}
                    bbsparqlendpoint={viewer.bbsparqlendpoint}
                    pageWidth={viewer.pageWidth}
                    parseUrlProperties={viewer.parseUrlProperties}
                    logoutbutton={viewer.logoutbutton}
                    noLoginButton
                    logoURL={viewer.logoURL}
                    onInfoClick={viewer.infoMessage ? () => this.setState({ popupInfoVisible: true }) : null}
                  />
                </Fragment>
              )}

              <div className={styles.main} style={{ maxWidth: viewer.pageWidth }}>
                <div className={styles.areaContainer}>
                  <Area
                    reauth={this.reauth.bind(this, viewer)}
                    config={viewer.mainArea}
                    onChange={this.onChange}
                    mode={mode}
                    repoName={repoName}
                    repoURL={viewer.repoURL}
                    localRepoURL={viewer.localRepoURL}
                    parseUrlProperties={viewer.parseUrlProperties}
                    sparqlErrorMessage={viewer.sparqlErrorMessage}
                    parameterValues={viewer.parameterValues}
                    save={this.save}
                  />
                </div>
              </div>

              {this.state.viewer.layout?.showFooter && (
                <Footer onClick={mode === 'edit' ? this.onHeaderClick : null} config={viewer.footer} />
              )}

            </div>
          </div>

          <InfoDialog
            style={{ zIndex: 1337 }}
            maxWidth="xl"
            open={Boolean(this.state.popupInfoVisible && viewer.infoMessage)}
            onClose={() => this.setState({ popupInfoVisible: false })}
          >
            <div className={styles.infoDialogueContainer}>
              <AutoHyperlink text={viewer.infoMessage} />
            </div>
          </InfoDialog>

          {this.state.dialogVisible && (
            <Dialog>
              <FormGroup>
                <Label>Layout</Label>
                <Select
                  className="dark-form-control form-control white-font-override"
                  onChange={(e) => this.selectLayout(e.target.value)}
                  value={this.state.viewer.layoutName || ''}
                >
                  <MenuItem value={LAYOUT_NAME.CUSTOM}>Custom</MenuItem>
                  <MenuItem value={LAYOUT_NAME.ORIGINAL}>Original</MenuItem>
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>Title</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.title || ''}
                  type="text"
                  onChange={(e) => this.updateViewer({ title: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>Subtitle</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.subtitle || ''}
                  type="text"
                  onChange={(e) => this.updateViewer({ subtitle: e.target.value })}
                />
              </FormGroup>

              <FormGroup>
                <Label>Info message</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.infoMessage || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, infoMessage: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.popupInfoAtStart ?? false}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          popupInfoAtStart: e.target.checked,
                        },
                      })
                    }
                  />
                  show popup info at start
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>pre app javascript</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.prejs || ''}
                  type="javascript"
                  onChange={(e) => {
                    this.setState({
                      viewer: { ...viewer, prejs: e.target.value },
                    })
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>bb repo and app parameters</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.bbsparqlendpoint || ''}
                  type="text"
                  onChange={(e) => {
                    this.setState({
                      viewer: { ...viewer, bbsparqlendpoint: e.target.value },
                    })
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Sparql endpoint</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.repoURL || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, repoURL: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>local sparql endpoint (http://localhost:7200/repositories/test)</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.localRepoURL}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, localRepoURL: e.target.value },
                    })
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>sparql endpoint error message</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.sparqlErrorMessage || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: {
                        ...viewer,
                        sparqlErrorMessage: e.target.value,
                      },
                    })
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>Logo URL</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.logoURL || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, logoURL: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label style={{ display: 'flex', gap: '1rem' }}>
                  <div style={{ flexGrow: 1 }}>
                    Global CSS in JSON formaat, bijvoorbeeld: {'{"fontFamily": "Exo"}'}
                  </div>
                  <FontListButton />
                </Label>
                <Input
                  className="dark-form-control"
                  value={viewer.css || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, css: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Pagina breedte</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.pageWidth || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, pageWidth: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>default parameter values </Label>
                <JsonTextEditor value={viewer.parameterValues || ''}
                                onValueChange={value => {
                                  this.setState({
                                    viewer: { ...viewer, parameterValues: value },
                                  })
                                }} />
              </FormGroup>

              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.update60Enabled}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          update60Enabled: e.target.checked,
                        },
                      })
                    }
                  />
                  enable update60 parameter refresh at 60s interval
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.parseUrlProperties}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          parseUrlProperties: e.target.checked,
                        },
                      })
                    }
                  />
                  parse url parameters as property values
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.notShowTop}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: { ...viewer, notShowTop: e.target.checked },
                      })
                    }
                  />{' '}
                  do not show top
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.logoutbutton}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          logoutbutton: e.target.checked,
                        },
                      })
                    }
                  />
                  show logout button in the header
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.notShowBottom}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          notShowBottom: e.target.checked,
                        },
                      })
                    }
                  />
                  do not show bottom
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>Config in JSON formaat</Label>
                <JsonEditor
                  exportFileName={`${this.state.viewer?.project?.path}-${this.state.viewer?.path}-config.${formatDate(new Date(), 'yyyy-MM-dd.HHmm')}.json`}
                  value={viewer}
                  onValueChange={config => {
                    if (config == null) return
                    this.setState(prevState => ({
                      viewer: {
                        ...config,
                        id: prevState.viewer.id,
                        user: prevState.viewer.user,
                        path: prevState.viewer.path,
                        project: prevState.viewer.project,
                        createdAt: prevState.viewer.createdAt,
                      }
                    }))
                  }} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.public}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: { ...viewer, public: e.target.checked },
                      })
                    }
                  />
                  publiek
                </Label>
              </FormGroup>
              <div style={{ textAlign: 'center' }}>
                <Button color="primary" style={{ minWidth: '120px', marginTop: '12px' }} onClick={this.onDialogOkClick}>
                  OK
                </Button>
              </div>
            </Dialog>
          )}
        </div>
        <GlobalToast position="bottom-center" />
        <ConfirmPopup />
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    )
  }
}

function getViewProps(props) {
  if (window.CONNECTED_APPS_STATIC)
    return {
      mode: 'preview',
      projectPath: window.PROJECT,
      viewerPath: window.VIEWER,
    }

  return {
    mode: props.match.params.mode,
    projectPath: props.match.params.projectPath,
    viewerPath: props.match.params.viewerPath,
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  loginState: state.login,
  pubsub: state.pubsub,
})

export default connect(mapStateToProps)(Viewer)
